import React, {FC, useEffect, useState} from "react";
import {BrandData} from "../../api/types";
import {Grid} from "@mui/material";
import {Button} from "@mui/material";
import {InlineImageSelector} from "../Reusable/InlineImageSelector";
import {GalleryItem} from "../Gallery/helpers";
import {DialogSimpleFieldText} from "../Reusable/DialogSimpleFieldText";
import {brandsCreateBrand, brandsUpdateBrand, sequencePollValidateExternalUrl} from "../../api/services";
import {DialogSimple} from "../Reusable/DialogSimple";


export const BrandUpdateDialog: FC<
  {
    brand: BrandData,
    open: boolean,
    onSave: () => void,
    onDismiss: () => void,
  }> = ({ brand, open, onSave, onDismiss }) => {

  const [poster, setPoster] = useState<GalleryItem | null>(null)
  const [logo, setLogo] = useState<GalleryItem | null>(null)
  const [name, setName] = useState<string | null>(null)
  const [title, setTitle] = useState<string | null>(null)
  const [description, setDescription] = useState<string | null>(null)
  const [websiteUrl, setWebsiteUrl] = useState<string | null>(null)
  const [websiteUrlTitle, setWebsiteUrlTitle] = useState<string | null>(null)

  const [canSave, setCanSave] = useState(false)
  const [urlValid, setUrlValid] = useState(true)
  const [urlExplanation, setUrlExplanation] = useState<string>()

  const setValues = (brand) => {
    setPoster(brand?.poster)
    setLogo(brand?.logo)
    setName(brand?.name)
    setTitle(brand?.title)
    setDescription(brand?.description)
    setWebsiteUrl(brand?.websiteUrl)
    setWebsiteUrlTitle(brand?.websiteUrlTitle)
    if (!brand) {
      setUrlExplanation(undefined)
      setUrlValid(true)
    }
  }

  useEffect(() => {
    setValues(brand)
  }, [brand])

  useEffect(() => {
    setCanSave(Boolean(poster && logo && name && title && description && websiteUrl && websiteUrlTitle && urlValid))
  }, [poster, logo, name, title, description, websiteUrl, websiteUrlTitle, urlValid])

  const dismiss = () => {
    setValues(null)
    onDismiss()
  }

  const save = async () => {

    let request = {
      name: name,
      title: title,
      description: description,
      logoMediaStorageItemId: logo.id,
      posterMediaStorageItemId: poster.id,
      websiteUrl: websiteUrl,
      websiteUrlTitle: websiteUrlTitle
    }

    if (brand && brand.id) {
      await brandsUpdateBrand(brand.id, request)
    } else {
      await brandsCreateBrand(request)
    }

    onSave()
    dismiss()
  }

  const validateWebsiteUrl = async () => {

    if (websiteUrl) {

      let response = await sequencePollValidateExternalUrl({ url: websiteUrl ?? '', excludeOrigins: [] })

      setUrlValid(response.data.canOpen)

      if (response.data.canOpen) {
        setUrlExplanation("URL can be opened")
      } else {
        setUrlExplanation(`URL cannot be opened (${response.data.statusCode})`)
      }
    }
  }

  return (
    <DialogSimple
        open={open}
        onDismiss={dismiss}
        maxWidth={"xs"}
        title={
          <>
            { brand ? (
              <>
                Update brand
              </>
            ) : (
              <>
                Create brand
              </>
            )}
          </>
        }
        content={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DialogSimpleFieldText label={"Name"} value={name} setValue={setName} error={name === null || name?.length === 0} />
              <DialogSimpleFieldText label={"Title"} value={title} setValue={setTitle} error={title === null || title?.length === 0} />
              <DialogSimpleFieldText label={"Description"} value={description} setValue={setDescription} error={description === null || description?.length === 0} multiline={true} />
              <DialogSimpleFieldText label={"Website URL"} value={websiteUrl} setValue={setWebsiteUrl} error={websiteUrl === null || websiteUrl?.length === 0 || !urlValid} errorText={urlExplanation} onBlurCapture={validateWebsiteUrl} helperText={urlExplanation} />
              <DialogSimpleFieldText label={"Website URL title"} value={websiteUrlTitle} setValue={setWebsiteUrlTitle} error={websiteUrlTitle === null || websiteUrlTitle?.length === 0} />

            </Grid>
            <Grid item xs={12} sm={6}>
              <InlineImageSelector item={poster} setItem={setPoster} title={"Poster"} type={'image/*'} />

            </Grid>
            <Grid item xs={12} sm={6}>
              <InlineImageSelector item={logo} setItem={setLogo} title={"Logo"} type={'image/*'} />

            </Grid>
          </Grid>
        }
        actions={
          <>
            <Button disabled={!canSave} onClick={save}>Save</Button>
            <Button onClick={dismiss}>Cancel</Button>
          </>
        }
    />
  )
}