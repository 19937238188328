import React, {FC, useEffect, useState} from "react";
import {
  DashboardCharts, DashboardGetOrganizationDataCreatorChartsByRangeQueryParams,
  GuidelinesData,
  SequencePollListData, SequencePollPreviewData
} from "../../api/types";
import {
  dashboardGetOrganizationDataChartsByRange,
  dashboardGetOrganizationDataChartsRealtime,
  dashboardGetOrganizationDataCreatorChartsByRange,
  dashboardGetOrganizationDataCreatorChartsRealtime,
  experienceRevisionsSubmitForReview,
  organizationGuidelinesGet,
  sequencePollChangeAccessType,
  sequencePollGetForUpdate,
  sequencePollGetPreview,
  sequencePollSaveUpdate,
  sequencePollSetActive,
  sequencePollSetArchived,
  sequencePollSetDeleted,
  sequencePollSetFeaturedListImage,
  sequencePollSetFlag,
  sequencePollSetLiveChatEnabled,
  sequencePollUnsetFlag
} from "../../api/services";
import {toast} from "react-toastify";
import {FilterType} from "../../pages/Dashboard";
import {LayoutWithSidebarWrapper, sidebarStyles} from "../Reusable/LayoutWithSidebars";
import {DialogSimple} from "../Reusable/DialogSimple";
import SequencePollAnalytics from "../SequencePollList/SequencePollAnalytics/SequencePollAnalytics";
import {Card, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Tooltip, Typography} from "@mui/material";
import {Box, Button} from "@mui/material";
import {AutoAwesome} from "@mui/icons-material";
import {InlineImageSelector} from "../Reusable/InlineImageSelector";
import ExperienceSettingsComponent from "../Home/ExperienceSettingsComponent";
import {prepareSequencePollForRedux} from "../../utils/sequenceSliceHelper";
import {setSequenceSliceData} from "../../redux/slices/sequenceSlice";
import {history} from "../../history";
import {useDispatch} from "react-redux";
import {SidebarItemSummary} from "../Reusable/SidebarItemSummary";
import {defaultEmptyExperienceSettings} from "../Home/Home";
import {DialogConfirmation} from "../Reusable/DialogConfirmation";
import {useAppContext} from "../../contexts/PermissionContext";
import {DialogRevisionHistory} from "./DialogRevisionHistory";
import {ApprovalStatusIcon} from "../Approval/ApprovalStatusIcon";
import {LiveChat} from "../LiveStreams/LiveChat";
import InfoIcon from "@mui/icons-material/Info";
import {defaultFilterDateRange} from "../Dashboard/DateRangeSummaryFilter";
import {ExperienceShareWithPublishers} from "./ExperienceShareWithPublishers";
import {ExperienceShareWithFollowers} from "./ExperienceShareWithFollowers";


export const ExperienceListSidebar: FC<{
  tab: string,
  selectedExperience: SequencePollListData,
  setSelectedExperience: React.Dispatch<React.SetStateAction<SequencePollListData>>,
  context: {
    refreshGrid: () => void,
  },
  tabOverride?: string
}> = ({
  tab,
  selectedExperience,
  setSelectedExperience,
  context,
  tabOverride,
}) => {

  const { permissions } = useAppContext()

  const dispatch = useDispatch()

  const [isInFeaturedList, setIsInFeaturedList] = useState(false)
  const [experienceChatEnabled, setExperienceChatEnabled] = useState(false)
  const [brandFeaturedImage, setBrandFeaturedImage] = useState(null)

  const [showAnalytics, setShowAnalytics] = useState(false)
  const [showRevisionHistory, setShowRevisionHistory] = useState(false)
  const [analyticsFilterData, setAnalyticsFilterData] = useState<DashboardGetOrganizationDataCreatorChartsByRangeQueryParams>(defaultFilterDateRange)
  const [showSettings, setShowSettings] = useState(false)
  const [analyticsChartsData, setAnalyticsChartsData] = useState<DashboardCharts>()
  const [lastHourAnalyticsChartsData, setLastHourAnalyticsChartsData] = useState<DashboardCharts>()
  const [settings, setSettings] = useState(defaultEmptyExperienceSettings())
  const [previewData, setPreviewData] = useState<SequencePollPreviewData>(null)
  const [isAvailableToMembers, setIsAvailableToMembers] = useState(false)
  const [isShareWithPublishersDialogVisible, setShareWithPublishersDialogVisible] = useState(false)
  const [isShareWithFollowersDialogVisible, setShareWithFollowersDialogVisible] = useState(false)

  const [guidelines, setGuidelines] = useState<GuidelinesData>()

  useEffect(() => {
    if (!guidelines && (permissions?.creatorManagement?.manageCreatorGuidelines || permissions?.overall?.organizationCreator)) {
      organizationGuidelinesGet().then(g => setGuidelines(g.data))
    }
  }, [guidelines, permissions])

  useEffect(() => {
    setIsInFeaturedList(selectedExperience?.sequencePoll?.isInBrandFeaturedList ?? false)
    setBrandFeaturedImage(selectedExperience?.sequencePoll?.brandFeaturedImage ?? null)
    setAnalyticsFilterData({
      ...analyticsFilterData,
      experienceId: selectedExperience?.sequencePoll?.id,
    })
    if (selectedExperience && selectedExperience.sequencePoll.editable) {
      sequencePollGetForUpdate(selectedExperience.sequencePoll.id).then(d => {
        setSettings(d.data.settings)
      })
    }

    let tempSelectedExperienceLiveStreamId = undefined
    let tempLiveChatReferenceId = undefined

    if (selectedExperience) {
      sequencePollGetPreview(selectedExperience.sequencePoll.id).then(d => setPreviewData(d.data))
      setIsAvailableToMembers(selectedExperience.sequencePoll.accessType == 'Extended')
      setExperienceChatEnabled(selectedExperience.sequencePoll.experienceChatEnabled)

      tempLiveChatReferenceId = selectedExperience.sequencePoll.id
      if (selectedExperience.assignedLiveStreamId) {
        tempLiveChatReferenceId = selectedExperience.assignedLiveStreamId
        tempSelectedExperienceLiveStreamId = selectedExperience.assignedLiveStreamId
      }
    } else {
      setPreviewData(undefined)
    }
    setLiveChatReferenceId(tempLiveChatReferenceId)
    setSelectedExperienceLiveStreamId(tempSelectedExperienceLiveStreamId)

  }, [selectedExperience])

  const fetchLastHourChartsData = () => {
    if (tabOverride === 'creator') {
      dashboardGetOrganizationDataCreatorChartsRealtime({
        experienceId: selectedExperience?.sequencePoll?.id,
        brandId: undefined,
        creatorId: undefined,
      }).then(d => {
        setLastHourAnalyticsChartsData(d.data)
      })
    } else {
      dashboardGetOrganizationDataChartsRealtime({
        experienceId: selectedExperience?.sequencePoll?.id,
        brandId: undefined,
        creatorId: undefined,
      }).then(d => {
        setLastHourAnalyticsChartsData(d.data)
      })
    }
  }

  useEffect(() => {
    let interval = setInterval(fetchLastHourChartsData, 5 * 1000)
    return () => clearInterval(interval)
  }, [selectedExperience, tabOverride])


  const updateBrandFeaturedImage = async (item) => {
    await sequencePollSetFeaturedListImage(selectedExperience.sequencePoll.id, { image: item })
    toast.success("Experience featured list image updated")
    selectedExperience.sequencePoll.brandFeaturedImage = item
    setBrandFeaturedImage(item)
  }

  const handleSaveSettings = async () => {

    let experienceUpdateObject = await sequencePollGetForUpdate(selectedExperience.sequencePoll.id)

    await sequencePollSaveUpdate({
      ...experienceUpdateObject.data,
      settings: settings
    }, {
      refresh: true,
    })
    setShowSettings(false)
    toast.success("Widget settings updated successfully")
    context.refreshGrid()
  }

  const setInFeaturedList = async (featured) => {
    if (featured) {
      await sequencePollSetFlag(selectedExperience.sequencePoll.id, "IS_IN_BRAND_FEATURED_LIST")
      toast.success("Experience included in featured list")
    } else {
      await sequencePollUnsetFlag(selectedExperience.sequencePoll.id, "IS_IN_BRAND_FEATURED_LIST")
      toast.success("Experience excluded from featured list")
    }
    selectedExperience.sequencePoll.isInBrandFeaturedList = featured
    setIsInFeaturedList(featured)
  }

  let copy = (value: string) => {
    toast.info('Value is copied to the clipboard', {
      autoClose: 1000,
    })
    navigator.clipboard.writeText(value)
  }

  const initializeAnalytics = (filter) => {
    if (tabOverride === 'creator') {
      dashboardGetOrganizationDataCreatorChartsByRange(filter).then(d => {
        setAnalyticsChartsData(d.data)
        setShowAnalytics(true)
      })
    } else {
      dashboardGetOrganizationDataChartsByRange(filter).then(d => {
        setAnalyticsChartsData(d.data)
        setShowAnalytics(true)
      })
    }
  }

  const handleFilterDataChange = (type: FilterType, value: any) => {

    let filter = type === 'BRAND' ? {
      ...analyticsFilterData,
      brandId: value,
    } : {
      ...analyticsFilterData,
      from: value.start,
      to: value.end,
    }

    setAnalyticsFilterData(filter)
    initializeAnalytics(filter)
  }

  const handleSequencePollEdit = async () => {
    let { data } = await sequencePollGetForUpdate(selectedExperience.sequencePoll.id)
    let canEdit = previewData.ownerType == 'Member' && permissions.overall.organizationMember || previewData.ownerType == 'Creator' && permissions.overall.organizationCreator
    let reduxData = prepareSequencePollForRedux(data, selectedExperience.sequencePoll.id, { canEdit: canEdit, resetLiveStreamIds: false })
    dispatch(setSequenceSliceData(reduxData))
    history.push(`/create-content?tab=${tab}`)
  }

  const handleAutopilot = () => {
    history.push(`/content/autopilot?experienceId=${selectedExperience.sequencePoll.id}`)
  }

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [confirmationDescription, setConfirmationDescription] = useState(null)
  const [confirmationAction, setConfirmationAction] = useState<'Archive' | 'Activate' | 'Delete' | null>(null)

  const [liveChatReferenceId, setLiveChatReferenceId] = useState<string>()
  const [selectedExperienceLiveStreamId, setSelectedExperienceLiveStreamId] = useState<string>()

  const isTemplate =
       selectedExperience?.sequencePoll?.organizationTemplate === true
    || selectedExperience?.sequencePoll?.globalTemplate === true
    || selectedExperience?.sequencePoll?.creatorTemplate === true


  const creatorSubmitForReview = () => {
    if (permissions?.overall?.organizationCreator) {
      experienceRevisionsSubmitForReview(selectedExperience.sequencePoll.id).then(() => {
        setSelectedExperience({
          ...selectedExperience,
          sequencePoll: {
            ...selectedExperience.sequencePoll,
            approvalStatus: 'InReviewWaitingMember'
          }
        })
        toast.success("Experience submitted for member review")
        context.refreshGrid()
      })
    }
  }

  const duplicate = async () => {
    let { data } = await sequencePollGetForUpdate(selectedExperience.sequencePoll.id)
    let reduxData = prepareSequencePollForRedux(data, '', { canEdit: true, resetLiveStreamIds: true })
    dispatch(setSequenceSliceData(reduxData))
    toast.info('Please rename your experience')
    history.push('/create-content')
  }

  const onConfirmationYes = async () => {
    switch (confirmationAction) {
      case 'Archive': {
        await sequencePollSetArchived(selectedExperience.sequencePoll.id)
        context.refreshGrid()
        toast.success("Experience archived successfully")
        break
      }
      case 'Delete': {
        await sequencePollSetDeleted(selectedExperience.sequencePoll.id)
        context.refreshGrid()
        toast.success("Experience deleted successfully")
        break
      }
      case 'Activate': {
        await sequencePollSetActive(selectedExperience.sequencePoll.id)
        context.refreshGrid()
        toast.success("Experience set active successfully")
        break
      }
      default: {
        break
      }
    }
  }

  const triggerAvailableToMembers = (available: boolean) => {
    if (available) {
      sequencePollChangeAccessType(selectedExperience.sequencePoll.id, 'Extended').then(() => {
        toast.success("Experience set as available to organization members")
        selectedExperience.sequencePoll.accessType = 'Extended'
        setIsAvailableToMembers(true)
      })
    } else {
      sequencePollChangeAccessType(selectedExperience.sequencePoll.id, 'Standard').then(() => {
        toast.success("Experience set as unavailable to organization members")
        selectedExperience.sequencePoll.accessType = 'Standard'
        setIsAvailableToMembers(false)
      })
    }
  }

  const setExperienceLiveChatEnabled = (enabled: boolean) => {

    sequencePollSetLiveChatEnabled(selectedExperience.sequencePoll.id, enabled).then(() => {
      toast.success("Experience chat " + (enabled ? "enabled" : "disabled"))
        selectedExperience.sequencePoll.experienceChatEnabled = enabled
        setExperienceChatEnabled(enabled)
    })
  }

  return (
    <LayoutWithSidebarWrapper>
      <DialogConfirmation
        title={"Are you sure?"}
        description={confirmationDescription}
        yesTitle={"Yes"}
        noTitle={"No"}
        open={confirmationDialogOpen}
        onPressYes={onConfirmationYes}
        onPressNo={() => {}}
        onDismiss={() => {
          setConfirmationDialogOpen(false)
          setConfirmationAction(null)
          setConfirmationDescription(null)
        }}/>
      <DialogRevisionHistory item={selectedExperience} setItem={setSelectedExperience} open={showRevisionHistory} onDismiss={(anyChanges) => {
        setShowRevisionHistory(false)
        if (anyChanges) {
          context.refreshGrid()
      } } } />
      <DialogSimple
        open={showAnalytics}
        title={"Chat and analytics"}
        onDismiss={() => setShowAnalytics(false) }
        maxWidth={"lg"}
        additionalStyles={{ overflowY: "hidden", paddingTop: '20px' }}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} md={5} sx={{ overflowY: "auto", maxHeight: "70vh" }}>
              <Card sx={{padding:"10px"}}>
                <Typography variant={"h2"}>
                  Manage chat
                  <Tooltip title={"If you assign a Livestream to this experience, the live chat will be displayed instead, and you need to manage that in the Live room"}>
                    <InfoIcon sx={{ position: "relative", top: "4px", marginLeft: "10px" }}/>
                  </Tooltip>
                </Typography>
                { selectedExperienceLiveStreamId ? (
                  <Typography sx={{margin: "5px"}}>
                    This experience has embedded live stream. Below is live chat attached to that live stream.
                  </Typography>
                ) : (
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox color={"primary"} checked={experienceChatEnabled} onChange={(e) => setExperienceLiveChatEnabled(e.target.checked)}/>} label={"Enable messaging for this experience"}/>

                  </FormGroup>
                ) }
                <LiveChat
                    organizationId={selectedExperience?.sequencePoll?.organizationId}
                    referenceId={liveChatReferenceId}
                    updateFrequencySeconds={5}
                    realTimeUpdatesEnabled={true}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={7} sx={{ overflowY: "auto", maxHeight: "70vh" }}>
              <Card sx={{padding:"10px"}}>
                <Typography variant={"h2"}>
                  Analytics
                </Typography>
                <Box sx={{ minHeight: "50vh" }}>
                  <SequencePollAnalytics
                    filterData={analyticsFilterData}
                    lastHourChartsData={lastHourAnalyticsChartsData}
                    chartsData={analyticsChartsData}
                    handleFilterDataChange={handleFilterDataChange}
                    showCloseButton={false}
                    closeAnalytics={() => setShowAnalytics(false)}
                    experienceId={selectedExperience?.sequencePoll?.id}
                    ownerType={tabOverride === "creator" ? "Creator" : "Business"}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        }/>
      <DialogSimple
        open={showSettings}
        title={"Widget settings"}
        onDismiss={() => setShowSettings(false) }
        maxWidth={"md"}
        content={
          <ExperienceSettingsComponent
            onDismiss={() => setShowSettings(false) }
            handleSaveChanges={handleSaveSettings}
            settings={settings}
            setSettings={setSettings}
            experienceId={selectedExperience?.sequencePoll?.id}
          />
        }/>
      <DialogSimple
        open={isShareWithFollowersDialogVisible}
        title={
          <Typography>
            Share this video experience with your followers
            <Tooltip title={
              <>
                <Typography>
                  Your followers receive an email with a link to this experience.
                </Typography>
                <Typography>
                  Sharing with followers twice will not send duplicate emails to followers who already received them.
                </Typography>
              </>
            }>
              <InfoIcon sx={{ position: "relative", top: "8px", marginLeft: "10px" }}/>
            </Tooltip>
          </Typography>
        }
        onDismiss={() => setShareWithFollowersDialogVisible(false)}
        content={
          <ExperienceShareWithFollowers
            onDismiss={() => setShareWithFollowersDialogVisible(false)}
            experienceId={selectedExperience?.sequencePoll?.id}
            />
        }
        maxWidth={"sm"}
      />
      <DialogSimple
        open={isShareWithPublishersDialogVisible}
        title={
          <Typography>
            Share this video experience with your publishers
            <Tooltip title={
              <>
                <Typography>
                  Your publishers receive an email with their unique affiliate links and all the assets they need to promote this video experience.
                </Typography>
                <Typography sx={{marginTop: "8px"}}>
                  Important! You can always edit this experience after submitting the campaign. However once it is shared it cannot be Archived/Deleted any longer as it would break the traffic for publishers.
                </Typography>
              </>}>
              <InfoIcon sx={{ position: "relative", top: "8px", marginLeft: "10px" }}/>
            </Tooltip>
          </Typography>}
        onDismiss={() => setShareWithPublishersDialogVisible(false)}
        content={
        <ExperienceShareWithPublishers
          onDismiss={() => setShareWithPublishersDialogVisible(false) }
          experienceId={selectedExperience?.sequencePoll?.id}
          />
      } maxWidth={"sm"}
      />

      { selectedExperience && (
        <>
          { !isTemplate && selectedExperience?.sequencePoll && (tabOverride === 'creator' || permissions?.overall?.organizationCreator) && (
            <Box sx={{ backgroundColor: "#F5F5F5", padding: "16px" }}>
              <Grid container alignItems={"center"} spacing={1}>

                { guidelines?.approvalType === 'PreApproved' ? (
                  <>
                    <Grid item>
                      <Typography variant={"h6"}>
                        Approval status
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ color: "#9E9E9E" }}>
                      You can share your content with no approval
                    </Grid>
                  </>
                  ) : (
                  <>
                    <Grid item>
                      <ApprovalStatusIcon startText={"Approval status"} status={selectedExperience.sequencePoll.approvalStatus} showTitle={true}/>
                    </Grid>
                    { selectedExperience.sequencePoll.approvalStatus == 'PendingReview' && (
                      <Grid item xs={12} sx={{ color: "#9E9E9E" }}>
                        Your content is not shared with reviewer
                      </Grid>
                    )}
                    { selectedExperience.sequencePoll.approvalStatus == 'InReviewWaitingCreator' && (
                      <Grid item xs={12} sx={{ color: "#9E9E9E" }}>
                        Content creator needs to submit a new revision
                      </Grid>
                    )}
                    { selectedExperience.sequencePoll.approvalStatus == 'InReviewWaitingMember' && (
                      <Grid item xs={12} sx={{ color: "#9E9E9E" }}>
                        A new revision is submitted for approval
                      </Grid>
                    )}
                    { selectedExperience.sequencePoll.approvalStatus == 'Approved' && (
                      <Grid item xs={12} sx={{ color: "#9E9E9E" }}>
                        Your content is approved and ready for sharing
                      </Grid>
                    )}
                    { selectedExperience.sequencePoll.approvalStatus == 'Rejected' && (
                      <Grid item xs={12} sx={{ color: "#9E9E9E" }}>
                        Your content is rejected. Start a new one!
                      </Grid>
                    )}
                  </>
                )}

              </Grid>
              { selectedExperience.sequencePoll.approvalStatus === 'PendingReview' && permissions?.overall?.organizationCreator ? (
                <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={creatorSubmitForReview}>Submit for review</Button>
              ) : (
                <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={() => setShowRevisionHistory(true) }>Revision dialog</Button>
              )}
            </Box>
          )}

          <Divider sx={sidebarStyles.divider}/>
          <SidebarItemSummary
            name={selectedExperience.sequencePoll.name}
            created={selectedExperience.sequencePoll.createdAt}
            extra1={"Brand: " + selectedExperience.brand.title}
            extra2={"Status: " + selectedExperience.sequencePoll.status + (selectedExperience.sequencePoll.source === "Shared" ? ", Shared" : "")}
          />
          <Divider sx={sidebarStyles.divider}/>


          { previewData?.resolvedUrl && (
            <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={() => copy(previewData.resolvedUrl)}>Public link</Button>
          )}

          { previewData?.shortResolvedUrl && (
            <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={() => copy(previewData.shortResolvedUrl)}>Public link (short)</Button>
          )}

          { permissions?.experience?.updateOverlaySettings && !isTemplate && selectedExperience.sequencePoll.overlayData?.publicUrl && (
            <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={() => copy(selectedExperience.sequencePoll.overlayData.publicUrl)}>Copy overlay link</Button>
          )}
          { !isTemplate && selectedExperience?.sequencePoll?.editable && permissions?.overall?.organizationCreator && (
              <>
                <Divider sx={sidebarStyles.divider}/>
                <FormControlLabel control={<Checkbox color={"primary"} checked={isAvailableToMembers} onChange={(e) => triggerAvailableToMembers(e.target.checked)}/>} label={"Allow organization members to manage this experience"}/>
              </>
          )}
          <Divider sx={sidebarStyles.divider}/>
          { !isTemplate && (
            <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={() => initializeAnalytics(analyticsFilterData) }>Chat and analytics</Button>
          )}
          { selectedExperience?.sequencePoll?.editable && (
            <>
              <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={handleSequencePollEdit}>Edit</Button>
              { permissions?.overall?.organizationMember && !isTemplate && permissions?.experience?.editorType == 'Standard' && (
                <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={() => setShowSettings(true) }>Edit visibility</Button>
              )}
              { selectedExperience.sequencePoll.status !== 'Archived' ? (
                <>
                  { selectedExperience.sequencePoll.canArchive && (
                    <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={() => {
                      setConfirmationDescription("Are you sure you want to archive this experience?")
                      setConfirmationAction('Archive')
                      setConfirmationDialogOpen(true)
                    }}>Move to archive</Button>
                  )}
                </>
              ) : (
                <>
                  <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={() => {
                    setConfirmationDescription("Are you sure you want to set this experience active?")
                    setConfirmationAction('Activate')
                    setConfirmationDialogOpen(true)
                  }}>Set active</Button>

                  { selectedExperience.sequencePoll.canDelete && (
                    <Button color={"error"} variant={"outlined"} sx={sidebarStyles.mainButton} onClick={() => {
                      setConfirmationDescription("Are you sure you want to delete this experience?")
                      setConfirmationAction('Delete')
                      setConfirmationDialogOpen(true)
                    }}>Delete</Button>
                  )}
                </>
              )}
            </>
          )}

          <Divider sx={sidebarStyles.divider}/>

          { isTemplate && !selectedExperience?.sequencePoll?.editable && (
            <Typography variant={"body1"}>
              Click below to reuse this template and start editing
            </Typography>
          )}

          { (isTemplate || selectedExperience?.sequencePoll?.editable) && (
            <Button variant={"contained"} sx={sidebarStyles.mainButton} onClick={() => {
              duplicate()
            }}>
              {isTemplate ? 'Use this template' : 'Duplicate'}
            </Button>
          )}

          { tabOverride !== 'creator' && !isTemplate && selectedExperience?.sequencePoll?.status === 'Active' && permissions.experience.publisherShareExperience && permissions.experience.manageMediaAssets && (
            <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={() => {
              setShareWithPublishersDialogVisible(true)
            }}>
              Share with publishers
            </Button>
          )}

          { tabOverride !== 'creator' && !isTemplate && selectedExperience?.sequencePoll?.status === 'Active' && permissions.experience.followerShareExperience && (permissions.experience.manageMediaAssets || true) && (
            <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={() => {
              setShareWithFollowersDialogVisible(true)
            }}>
              Share with followers
            </Button>
          )}

          { !permissions.overall.organizationTypeAffiliate && !isTemplate && selectedExperience?.sequencePoll?.editable && permissions.overall.organizationMember && !selectedExperience?.creator && (
            <>
              { permissions?.menu?.autopilot && permissions?.autopilot?.liveFocus && false && (
                <Button variant={"outlined"} sx={sidebarStyles.mainButton} onClick={handleAutopilot}>Autopilot</Button>
              )}
              <Divider sx={sidebarStyles.divider}/>
              <FormGroup>
                <FormControlLabel control={<Checkbox color={"primary"} checked={isInFeaturedList} onChange={(e) => setInFeaturedList(e.target.checked)}/>} label={"Show this experience in featured list"}/>
              </FormGroup>
              <InlineImageSelector item={brandFeaturedImage} setItem={updateBrandFeaturedImage} title={"featured image"} type={"image/*"}/>

              { permissions.overall.organizationMember && false && (
                <>
                  <Divider sx={sidebarStyles.divider}/>
                  <Typography variant={"h5"}>
                    <AutoAwesome sx={{ color: '#FF00EC' }} /> <span style={{ verticalAlign: "super" }}>AI Features</span>
                  </Typography>
                  <Typography variant={"body1"}>
                    {selectedExperience.sequencePoll.aiStatistics.objectFocus.summary}
                  </Typography>
                </>
              )}
            </>
          )}
        </>
      )}
    </LayoutWithSidebarWrapper>
  )
}