import React, { FC } from 'react'
import { GridComponent } from '../Reusable/GridComponent'
import { SequencePollListData } from '../../api/types'
import { Avatar, Grid, Typography } from '@mui/material'
import { ApprovalStatusIcon } from '../Approval/ApprovalStatusIcon'

export const ExperienceListGridComponent: FC<{
  item: SequencePollListData
  selected: boolean
  showApprovalStatus: boolean
}> = ({ item, selected, showApprovalStatus }) => {
  const styles = {
    logo: {
      // opacity: 0.8,
    },
    title: {
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      textOveflow: 'ellipsis',
      // letterSpacing: '-1px',
    },
    brand: {
      minHeight: '21px',
      textOveflow: 'ellipsis',
    },
  }

  const label = (brandLogo, brandName) => {
    return (
      <Grid container alignItems={'center'} spacing={2} pr={1}>
        <Grid item xs={3}>
          <Avatar src={brandLogo} style={styles.logo} />
        </Grid>
        <Grid item xs={7} display="flex" flexDirection="column">
          <Typography fontSize={14} noWrap variant={'h6'} style={styles.title}>
            {item.sequencePoll.name}
          </Typography>
          <Typography
            noWrap
            fontSize={13}
            variant={'body1'}
            color={'secondary'}
            style={styles.brand}
          >
            {brandName}
          </Typography>
        </Grid>
        {showApprovalStatus && (
          <Grid item xs={2}>
            <ApprovalStatusIcon
              status={item.sequencePoll.approvalStatus}
              showTitle={false}
            />
          </Grid>
        )}
      </Grid>
    )
  }

  return (
    <GridComponent
      thumbnail={<ExperiencePreview item={item} />}
      label={
        <>
          {item.creator
            ? label(item.creator.pictureFileLocation, item.creator.name)
            : label(item.brand.logo, item.brand.title)}
        </>
      }
      selected={selected}
    />
  )
}

const ExperiencePreview: FC<{ item: SequencePollListData }> = ({ item }) => {
  const styles = {
    image: {
      width: '100%',
      height: '100px',
      objectFit: 'cover',
      animation: 'fadeIn 0.1s ease 1',
    },
  }

  return (
    <Grid container>
      {item.rootCards.map((c, index) => (
        <Grid key={`experience-preview-${index}-${c.id}`} item xs={6}>
          <img src={c.image} style={styles.image} alt={''} />
        </Grid>
      ))}
    </Grid>
  )
}
