import React, { FC, useEffect, useRef, useState } from 'react'
import {
  LiveChatHistoryItem,
  LiveChatUserProfileDataObject,
} from '../../api/types'
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Popover,
  Typography,
} from '@mui/material'
import {
  liveChatBlockUser,
  liveChatGetCurrentUser,
  liveChatGetHistory,
  liveChatJoinConversation,
  liveChatMessageDelete,
  liveChatMessagePin,
  liveChatMessageUnpin,
  liveChatReplyMessage, liveChatRequestDetails,
  liveChatSendMessage,
} from '../../api/services'
import { DialogSimpleFieldText } from '../Reusable/DialogSimpleFieldText'
import { toast } from 'react-toastify'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { DialogConfirmation } from '../Reusable/DialogConfirmation'
import { DialogTextField } from '../Reusable/DialogTextField'
import moment from 'moment'
import {Virtuoso} from "react-virtuoso";

export const LiveChat: FC<{
  organizationId: string,
  referenceId: string,
  updateFrequencySeconds: number,
  realTimeUpdatesEnabled: boolean,
}> = ({ organizationId, referenceId, updateFrequencySeconds, realTimeUpdatesEnabled }) => {

  const [currentUser, setCurrentUser] =
      useState<LiveChatUserProfileDataObject>()
  const [history, setHistory] = useState<LiveChatHistoryItem[]>()
  const [pinned, setPinned] = useState<LiveChatHistoryItem[]>()

  const [userName, setUserName] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  const divRef = useRef()

  useEffect(() => {
    fetchLiveChatData()
  }, [organizationId, referenceId])

  useEffect(() => {
    if (realTimeUpdatesEnabled) {
      let interval = setInterval(fetchLiveChatData, updateFrequencySeconds * 1000)
      return () => clearInterval(interval)
    }
  }, [organizationId, referenceId, currentUser, history, updateFrequencySeconds, realTimeUpdatesEnabled])

  const fetchLiveChatData = () => {
    if (organizationId && referenceId) {
      liveChatGetHistory(organizationId, referenceId).then(h => {
        let scrollDown =
            !history ||
            (JSON.stringify(history) != JSON.stringify(h.data.history))
        if (scrollDown && divRef.current) {
          setHistory(h.data.history)
          setPinned(h.data.pinned)
        }
      })
      if (!currentUser) {
        liveChatGetCurrentUser(organizationId, referenceId).then(u => {
          setCurrentUser(u.data)
        })
      }
    }
  }

  const [messageMenuOpen, setMessageMenuOpen] = useState(false)
  const [messageAnchor, setMessageAnchor] = useState()

  const [messageBlockConfirmationOpen, setMessageBlockConfirmationOpen] =
      useState(false)
  const [messageRequestDetailsConfirmationOpen, setMessageRequestDetailsConfirmationOpen] =
      useState(false)
  const [messageDeleteConfirmationOpen, setMessageDeleteConfirmationOpen] =
      useState(false)
  const [selectedMessage, setSelectedMessage] = useState<LiveChatHistoryItem>()

  const [messageReplyOpen, setMessageReplyOpen] = useState(false)
  const [replyMessage, setReplyMessage] = useState('')

  const onMessageReplyDismiss = () => {
    setMessageReplyOpen(false)
    setReplyMessage('')
  }
  const onMessageMenuClose = () => {
    setMessageMenuOpen(false)
    setMessageAnchor(null)
  }

  const onMessageBlockConfirmationDismiss = () => {
    setMessageBlockConfirmationOpen(false)
  }
  const onMessageRequestDetailsConfirmationDismiss = () => {
    setMessageRequestDetailsConfirmationOpen(false)
  }
  const onMessageDeleteConfirmationDismiss = () => {
    setMessageDeleteConfirmationOpen(false)
  }

  const joinConversation = () => {
    liveChatJoinConversation(organizationId, referenceId, {
      name: userName,
      timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
    }).then(d => {
      toast.success('Joined live chat!')
      fetchLiveChatData()
    })
  }

  const sendMessage = () => {
    liveChatSendMessage(organizationId, referenceId, { message: message }).then(
        d => {
          fetchLiveChatData()
          setMessage('')
        },
    )
  }

  const messageBlock = () => {
    liveChatBlockUser(
        organizationId,
        referenceId,
        selectedMessage.user.id,
    ).then(d => {
      toast.success('User blocked')
      fetchLiveChatData()
    })
  }

  const messageRequestDetails = () => {
    liveChatRequestDetails(organizationId, referenceId, { userId: selectedMessage.user.id }).then(
      d => {
        toast.success("Details sent")
      }
    )
  }

  const messageDelete = () => {
    liveChatMessageDelete(organizationId, referenceId, selectedMessage.id).then(
        d => {
          toast.success('Message deleted')
          fetchLiveChatData()
        },
    )
  }

  const messageReply = () => {
    liveChatReplyMessage(organizationId, referenceId, {
      replyMessageId: selectedMessage.id,
      message: replyMessage,
    }).then(d => {
      fetchLiveChatData()
    })
  }

  const onPin = () => {
    liveChatMessagePin(organizationId, referenceId, selectedMessage.id).then(
        d => {
          fetchLiveChatData()
        },
    )
  }

  const onUnpin = () => {
    liveChatMessageUnpin(organizationId, referenceId, selectedMessage.id).then(
        d => {
          fetchLiveChatData()
        },
    )
  }

  const renderMessage = (index: number, h: LiveChatHistoryItem) => {
    return (
        <Box
            sx={{
              marginTop: '5px',
              backgroundColor: '#fff',
              padding: '5px',
              borderRadius: '5px',
            }}
            key={h.id}
        >
          <Grid container spacing={1}>
            <Grid item sx={{ marginTop: '3px' }}>
              <Avatar src={h.user.picture} />
            </Grid>
            <Grid item xs>
              <Grid container direction={'column'}>
                <Grid item>
                  <Grid container direction={'row'} alignItems={'flex-end'}>
                    <Grid
                        item
                        sx={{
                          marginRight: '5px',
                          fontSize: '10px',
                          marginBottom: '2px',
                          color: '#333',
                        }}
                    >
                      {moment(h.createdAt * 1000).fromNow()}
                    </Grid>
                    <Grid item sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                      {h.user.name}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs sx={{ fontSize: '12px'}}>
                  {h.message}
                </Grid>
              </Grid>
            </Grid>
            {h.pinned && (
                <Grid item sx={{ marginTop: '5px' }}>
                  <Typography>📌</Typography>
                </Grid>
            )}
            <Grid item>
              <IconButton
                  onClick={e => {
                    setMessageAnchor(e.currentTarget)
                    setSelectedMessage(h)
                    setMessageMenuOpen(true)
                  }}
              >
                <MoreHorizIcon />
              </IconButton>
            </Grid>
          </Grid>
          {h.replies &&
              h.replies.map(reply => (
                  <Box sx={{ marginLeft: '15px' }}>{renderMessage(0, reply)}</Box>
              ))}
        </Box>
    )
  }

  return (
      <Card>
        <DialogConfirmation
          title={'Are you sure?'}
          description={
            'Blocking hides all user messages from everyone else, including you'
          }
          yesTitle={'Block'}
          noTitle={'Cancel'}
          open={messageBlockConfirmationOpen}
          onPressYes={messageBlock}
          onPressNo={onMessageBlockConfirmationDismiss}
          onDismiss={onMessageBlockConfirmationDismiss}
        />
        <DialogConfirmation
          title={'Request user information'}
          description={
            'Confirming this action will send contact details of this user to organization owner email.'
          }
          yesTitle={'Request'}
          noTitle={'Cancel'}
          open={messageRequestDetailsConfirmationOpen}
          onPressYes={messageRequestDetails}
          onPressNo={onMessageRequestDetailsConfirmationDismiss}
          onDismiss={onMessageRequestDetailsConfirmationDismiss}
        />
        <DialogConfirmation
            title={'Are you sure?'}
            description={
              'Deleting message will remove it from chat for all users, including you'
            }
            yesTitle={'Delete'}
            noTitle={'Cancel'}
            open={messageDeleteConfirmationOpen}
            onPressYes={messageDelete}
            onPressNo={onMessageDeleteConfirmationDismiss}
            onDismiss={onMessageDeleteConfirmationDismiss}
        />
        <DialogTextField
            title={'Reply to this message'}
            description={'Enter your reply'}
            yesTitle={'Reply'}
            noTitle={'Cancel'}
            open={messageReplyOpen}
            textFieldLabel={'Message'}
            value={replyMessage}
            setValue={setReplyMessage}
            onPressYes={messageReply}
            onPressNo={onMessageReplyDismiss}
            onDismiss={onMessageReplyDismiss}
        />
        <Popover
            sx={{ margin: 0, padding: 0 }}
            open={messageMenuOpen}
            onClose={onMessageMenuClose}
            anchorEl={messageAnchor}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          { currentUser ? (
              <>
                {selectedMessage?.type === 'Message' && selectedMessage.user.details && (
                  <Button
                    onClick={() => {
                      onMessageMenuClose()
                      setMessageRequestDetailsConfirmationOpen(true)
                    }}
                  >
                    Request details
                  </Button>
                )}
                {selectedMessage?.type === 'Message' && !selectedMessage.replyTo && (
                    <Button
                        onClick={() => {
                          onMessageMenuClose()
                          setMessageReplyOpen(true)
                        }}
                    >
                      Reply
                    </Button>
                )}
                {selectedMessage?.type === 'Message' && (
                    <Button
                        onClick={() => {
                          onMessageMenuClose()
                          setMessageDeleteConfirmationOpen(true)
                        }}
                    >
                      Delete
                    </Button>
                )}
                <Button
                    onClick={() => {
                      onMessageMenuClose()
                      setMessageBlockConfirmationOpen(true)
                    }}
                >
                  Block
                </Button>
                {selectedMessage?.pinned && (
                    <Button
                        onClick={() => {
                          onMessageMenuClose()
                          onUnpin()
                        }}
                    >
                      Unpin
                    </Button>
                )}
                {!selectedMessage?.pinned && selectedMessage?.type === 'Message' && (
                    <Button
                        onClick={() => {
                          onMessageMenuClose()
                          onPin()
                        }}
                    >
                      Pin
                    </Button>
                )}
              </>
          ) : (
              <>
                <Typography sx={{margin: "5px"}}>Join chat first</Typography>
              </>
          )}
        </Popover>
        <Box p={1} m={0}>
          {/*<Typography variant={"h3"}>*/}
          {/*  Live chat*/}
          {/*</Typography>*/}
          <Grid container>
            {pinned && pinned.length > 0 && (
                <Box sx={{ width: '100%' }}>
                  <Typography sx={{ margin: '5px' }} variant={'h4'}>
                    Pinned messages
                  </Typography>
                  <Grid
                      item
                      xs={12}
                      sx={{
                        maxHeight: '400px',
                        overflowY: 'scroll',
                        backgroundColor: '#F5F5F5',
                        borderRadius: '5px',
                        padding: '5px',
                        marginTop: '5px',
                      }}
                  >
                    {pinned.map(h => (
                        <>{renderMessage(0, h)}</>
                    ))}
                  </Grid>
                </Box>
            )}
            <Typography sx={{ margin: '5px' }} variant={'h4'}>
              Chat history
            </Typography>
            <Grid
              item
              xs={12}
              sx={{
                padding: "5px",
                backgroundColor: '#F5F5F5',
                borderRadius: '5px',
                marginTop: '5px',
              }}
            >
              <Virtuoso
                ref={divRef}
                followOutput={'auto'}
                initialTopMostItemIndex={(history?.length ?? 0) - 1}
                data={history}
                itemContent={renderMessage}
                style={{
                  // pinned container has 60px, 550-60=490px
                  height: `${pinned?.length ? 490 - pinned.length * 73 : 550}px`,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {currentUser ? (
                  <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs sx={{ marginTop: '5px' }}>
                      <DialogSimpleFieldText
                          variant={'outlined'}
                          label={'Message'}
                          value={message}
                          setValue={setMessage}
                          error={false}
                          helperText={'Enter your message and click Send'}
                          onKeyDown={e => {
                            if (
                                (e.code == 'Enter' || e.code == 'NumpadEnter') &&
                                message.length > 0
                            ) {
                              sendMessage()
                            }
                          }}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                          variant={'contained'}
                          disabled={!message || message.length == 0}
                          onClick={sendMessage}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
              ) : (
                  <>
                    { organizationId && referenceId ? (

                        <Grid container spacing={1} alignItems={'center'}>
                          <Grid item xs>
                            <DialogSimpleFieldText
                                label={'Name'}
                                value={userName}
                                setValue={setUserName}
                                error={false}
                                helperText={'Enter your name to join conversation'}
                            />
                          </Grid>
                          <Grid item>
                            <Button
                                variant={'outlined'}
                                disabled={!userName || userName.length == 0}
                                onClick={joinConversation}
                            >
                              Join
                            </Button>
                          </Grid>
                        </Grid>
                    ) : (
                        <Typography sx={{margin: "5px"}}>Chat is not embedded on any experience</Typography>
                    )}
                  </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Card>
  )
}
